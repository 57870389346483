/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    position: relative;
}